<template>
    <div class="checkout">
        <section id="section-booking" class="section booking customCheckout">
            <div class="row justify-content-md-center">
                <div class="col-12">
                    <div class="voffset-90"></div>
                    <div class="container">
                        <div class="row ">
                            <div
                                class="col-lg-12"
                                data-aos-id="booking-section"
                            >
                                <h4 class="white title">Kosár és Fizetés</h4>
                            </div>
                        </div>
                    </div>
                    <div
                        class="container"
                        v-if="cart && Object.keys(cart).length > 0"
                    >
                        <CartTable :cart="cart" v-on:update-cart="getCart" />
                        <template v-if="cart.items && cart.items.length > 0">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="update()"
                                class="modal-succ"
                                >Kosár frissítése</b-button
                            >
                            <hr />
                            <b style="color: white"
                                >Várható kiszállítási idő Vácon belül:
                                {{ deliveryTime }} perc</b
                            ><br />
                            <b style="color: white;"
                                >Várható kiszállítási idő Vácon kívül:
                                {{ deliveryTimeSuburban }} perc</b
                            >
                            <div class="voffset-90"></div>
                            <template v-if="notLoggedIn">
                                <h2 class="text-center">Bejelentkezés</h2>
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <form
                                            v-on:submit.prevent="doLogin"
                                            class="booking-form checkout-form"
                                        >
                                            <div class="form-group">
                                                <label>Email cím</label>
                                                <input
                                                    type="email"
                                                    v-model="form.email"
                                                    name="email"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Jelszó</label>
                                                <input
                                                    type="password"
                                                    v-model="form.password"
                                                    name="password"
                                                    class="form-control"
                                                />
                                            </div>

                                            <button
                                                type="submit"
                                                class="btn w-100 bt-orange add-to-cart-btn"
                                            >
                                                Bejelentkezés
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </template>
                            <div class="voffset-90"></div>
                            <div class="row">
                                <div class="col-12">
                                    <form
                                        class="booking-form checkout-form"
                                        v-on:submit.prevent="doOrder"
                                    >
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h5
                                                    style="color: white; margin-bottom: 31px;"
                                                >
                                                    Szállítási adatok
                                                </h5>
                                                <div
                                                    class="form-group"
                                                    v-if="
                                                        shippings &&
                                                            shippings.length > 0
                                                    "
                                                >
                                                    <label
                                                        for="shipment_method_id"
                                                        >Címeim</label
                                                    >
                                                    <select
                                                        class="custom-select"
                                                        id="cimeim_shipping"
                                                        v-model="
                                                            cimeim_shipping
                                                        "
                                                    >
                                                        <option :value="null"
                                                            >Kérem
                                                            válasszon</option
                                                        >
                                                        <option
                                                            v-for="(item,
                                                            index) in shippings"
                                                            v-bind:key="
                                                                'cimeim_shipping-' +
                                                                    index
                                                            "
                                                            :selected="
                                                                item.is_default
                                                            "
                                                            :value="item.id"
                                                            >{{ index + 1 }}.
                                                            cím:
                                                            {{ item.city_name }}
                                                            {{
                                                                item.address
                                                            }}</option
                                                        >
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label
                                                        for="shipping_lastname"
                                                        >Vezetéknév</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="shipping_lastname"
                                                        placeholder="Vezetéknév"
                                                        v-model="
                                                            cart.shipping_lastname
                                                        "
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label
                                                        for="shipping_firstname"
                                                        >Keresztnév</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="shipping_firstname"
                                                        placeholder="Keresztnév"
                                                        v-model="
                                                            cart.shipping_firstname
                                                        "
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label for="email"
                                                        >E-mail cím</label
                                                    >
                                                    <input
                                                        type="email"
                                                        class="form-control"
                                                        id="email"
                                                        placeholder="E-mail cím"
                                                        v-model="cart.email"
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label for="phone"
                                                        >Telefonszám</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="phone"
                                                        placeholder="Telefonszám"
                                                        v-model="cart.phone"
                                                    />
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="
                                                        shipments &&
                                                            shipments.length > 0
                                                    "
                                                >
                                                    <label
                                                        for="shipment_method_id"
                                                        >Szállítási mód</label
                                                    >
                                                    <select
                                                        class="custom-select"
                                                        id="shipment_method_id"
                                                        v-model="
                                                            cart.shipment_method_id
                                                        "
                                                        v-on:change="
                                                            emptyPayment
                                                        "
                                                    >
                                                        <option :value="null"
                                                            >Kérem
                                                            válasszon</option
                                                        >
                                                        <option
                                                            v-for="(item,
                                                            index) in shipments"
                                                            v-bind:key="
                                                                'shipment-' +
                                                                    index
                                                            "
                                                            :value="item.id"
                                                            >{{
                                                                item.name
                                                            }}</option
                                                        >
                                                    </select>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="
                                                        payments &&
                                                            payments.length > 0
                                                    "
                                                >
                                                    <label
                                                        for="payment_method_id"
                                                        >Fizetési mód</label
                                                    >
                                                    <select
                                                        class="custom-select"
                                                        id="payment_method_id"
                                                        v-model="
                                                            cart.payment_method_id
                                                        "
                                                    >
                                                        <option :value="null"
                                                            >Kérem
                                                            válasszon</option
                                                        >
                                                        <option
                                                            v-for="(item,
                                                            index) in filteredPayments"
                                                            v-bind:key="
                                                                'payment-' +
                                                                    index
                                                            "
                                                            :value="item.id"
                                                            >{{
                                                                item.name
                                                            }}</option
                                                        >
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h5 style="color: white">
                                                    Számlázási adatok
                                                </h5>
                                                <div
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        v-model="
                                                            cart.same_billing
                                                        "
                                                        id="same_billing"
                                                        value="1"
                                                    />
                                                    <label
                                                        class="custom-control-label white black"
                                                        for="same_billing"
                                                        style="font-size: 15px !important;"
                                                        >Számlázási adataim
                                                        megegyeznek a szállítási
                                                        adatokkal.</label
                                                    >
                                                </div>
                                                <template
                                                    v-if="!cart.same_billing"
                                                >
                                                    <div
                                                        class="form-group"
                                                        v-if="
                                                            shippings &&
                                                                shippings.length >
                                                                    0
                                                        "
                                                    >
                                                        <label
                                                            for="shipment_method_id"
                                                            >Címeim</label
                                                        >
                                                        <select
                                                            class="custom-select"
                                                            id="cimeim_billing"
                                                            v-model="
                                                                cimeim_billing
                                                            "
                                                        >
                                                            <option
                                                                :value="null"
                                                                >Kérem
                                                                válasszon</option
                                                            >
                                                            <option
                                                                v-for="(item,
                                                                index) in shippings"
                                                                v-bind:key="
                                                                    'cimeim_billing-' +
                                                                        index
                                                                "
                                                                :selected="
                                                                    item.is_default
                                                                "
                                                                :value="item.id"
                                                                >{{
                                                                    index + 1
                                                                }}. cím:
                                                                {{
                                                                    item.city_name
                                                                }}
                                                                {{
                                                                    item.address
                                                                }}</option
                                                            >
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label
                                                            for="billing_lastname"
                                                            >Vezetéknév</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="billing_lastname"
                                                            placeholder="Vezetéknév"
                                                            v-model="
                                                                cart.billing_lastname
                                                            "
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label
                                                            for="billing_firstname"
                                                            >Keresztnév</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="billing_firstname"
                                                            placeholder="Keresztnév"
                                                            v-model="
                                                                cart.billing_firstname
                                                            "
                                                        />
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        v-if="
                                                            cart.shipment_method_id !==
                                                                1 &&
                                                                cities &&
                                                                cities.length >
                                                                    0
                                                        "
                                                    >
                                                        <label
                                                            for="billing_city"
                                                            >Település</label
                                                        >
                                                        <select
                                                            class="custom-select"
                                                            id="billing_city"
                                                            v-model="
                                                                cart.billing_city
                                                            "
                                                        >
                                                            <option
                                                                :value="null"
                                                                >Kérem
                                                                válasszon</option
                                                            >
                                                            <option
                                                                v-for="(item,
                                                                index) in cities"
                                                                v-bind:key="
                                                                    'city-' +
                                                                        index
                                                                "
                                                                :value="item.id"
                                                                >{{ item.name }}
                                                                <template
                                                                    v-if="
                                                                        item.minimum_shipping_price >
                                                                            0
                                                                    "
                                                                    >- min.:
                                                                    {{
                                                                        item.minimum_shipping_price_n
                                                                    }}</template
                                                                >
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        v-if="
                                                            cart.shipment_method_id !==
                                                                1
                                                        "
                                                    >
                                                        <label
                                                            for="billing_address"
                                                            >Utca, hsz.</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="billing_address"
                                                            placeholder="Utca, hsz."
                                                            v-model="
                                                                cart.billing_address
                                                            "
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label
                                                            for="company_name"
                                                            >Cégnév</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="company_name"
                                                            placeholder="Cégnév"
                                                            v-model="
                                                                cart.company_name
                                                            "
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="vat_number"
                                                            >Adószám</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="vat_number"
                                                            placeholder="Adószám"
                                                            v-model="
                                                                cart.vat_number
                                                            "
                                                        />
                                                    </div>
                                                </template>
                                                <template
                                                    v-if="
                                                        !user ||
                                                            Object.keys(user)
                                                                .length < 1
                                                    "
                                                >
                                                    <hr />
                                                    <h5 style="color: white">
                                                        Adj meg egy jelszót,
                                                        amivel regisztrálunk
                                                        neked egy fiókot.
                                                    </h5>
                                                    <div class="form-group">
                                                        <label for="password"
                                                            >Jelszó</label
                                                        >
                                                        <input
                                                            type="password"
                                                            class="form-control"
                                                            id="password"
                                                            autocomplete="off"
                                                            placeholder="Jelszó"
                                                            v-model="
                                                                cart.password
                                                            "
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label
                                                            for="password_confirmation"
                                                            >Jelszó újra</label
                                                        >
                                                        <input
                                                            type="password"
                                                            class="form-control"
                                                            autocomplete="off"
                                                            id="password_confirmation"
                                                            placeholder="Jelszó"
                                                            v-model="
                                                                cart.password_confirmation
                                                            "
                                                        />
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="col-md-6">
                                                <div
                                                    class="form-group"
                                                    v-if="
                                                        cart.shipment_method_id !==
                                                            1 &&
                                                            cities &&
                                                            cities.length > 0
                                                    "
                                                >
                                                    <label for="shipping_city"
                                                        >Település</label
                                                    >
                                                    <select
                                                        class="custom-select"
                                                        id="shipping_city"
                                                        v-model="
                                                            cart.shipping_city
                                                        "
                                                    >
                                                        <option :value="null"
                                                            >Kérem
                                                            válasszon</option
                                                        >
                                                        <option
                                                            v-for="(item,
                                                            index) in cities"
                                                            v-bind:key="
                                                                'city-' + index
                                                            "
                                                            :value="item.id"
                                                            >{{ item.name }}
                                                            <template
                                                                v-if="
                                                                    item.minimum_shipping_price >
                                                                        0
                                                                "
                                                                >- min.:
                                                                {{
                                                                    item.minimum_shipping_price_n
                                                                }}</template
                                                            ></option
                                                        >
                                                    </select>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="
                                                        cart.shipment_method_id !==
                                                            1
                                                    "
                                                >
                                                    <label
                                                        for="shipping_address"
                                                        >Utca, hsz.</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="shipping_address"
                                                        placeholder="Utca, hsz."
                                                        v-model="
                                                            cart.shipping_address
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        for="exampleFormControlTextarea1"
                                                        >Megjegyzés</label
                                                    >
                                                    <textarea
                                                        class="form-control"
                                                        v-model="cart.note"
                                                        id="exampleFormControlTextarea1"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        for="szall_kolt"
                                                        class="col-sm-8 col-form-label"
                                                        >Szállítási
                                                        költség</label
                                                    >
                                                    <div class="col-sm-4">
                                                        <input
                                                            type="text"
                                                            readonly
                                                            class="form-control-plaintext summary"
                                                            id="szall_kolt"
                                                            :value="
                                                                shipmentPrice +
                                                                    ' Ft'
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label
                                                        for="total"
                                                        class="col-sm-8 col-form-label"
                                                        >Összesen</label
                                                    >
                                                    <div class="col-sm-4">
                                                        <input
                                                            type="text"
                                                            readonly
                                                            class="form-control-plaintext summary"
                                                            id="total"
                                                            :value="
                                                                cartTotal +
                                                                    ' Ft'
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="form-group row"
                                                    v-if="minOrderValue > 0"
                                                >
                                                    <label
                                                        for="total"
                                                        style="color: red !important;"
                                                        class="col-sm-8 col-form-label"
                                                        >Minimum
                                                        rendelésértékhez hiányzó
                                                        összeg</label
                                                    >
                                                    <div class="col-sm-4">
                                                        <input
                                                            type="text"
                                                            readonly
                                                            style="color: red !important;"
                                                            class="form-control-plaintext summary"
                                                            id="total"
                                                            :value="
                                                                minOrderValue +
                                                                    ' Ft'
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        v-model="cart.aszf"
                                                        id="aszf"
                                                        value="1"
                                                    />
                                                    <label
                                                        class="custom-control-label white"
                                                        for="aszf"
                                                        style="font-size: 15px !important;"
                                                        >Elfogadom az
                                                        <router-link
                                                            to="/adatkezeles"
                                                            >adatvédelmi
                                                            nyilatkozatot</router-link
                                                        >
                                                        és az
                                                        <router-link to="/aszf"
                                                            >általános
                                                            szerződési
                                                            feltételeket</router-link
                                                        >.</label
                                                    >
                                                </div>
                                                <div
                                                    v-if="subscriptionShow"
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        v-model="
                                                            cart.subscription
                                                        "
                                                        id="subscription"
                                                        value="1"
                                                    />
                                                    <label
                                                        class="custom-control-label white"
                                                        for="subscription"
                                                        style="font-size: 15px !important;"
                                                        >Szeretnék értesülni a
                                                        Farm legújabb
                                                        ajánlatairól.</label
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="voffset-90"></div>
                                                <div class="text-center">
                                                    <button
                                                        v-bind:disabled="
                                                            disabled
                                                        "
                                                        class="bt-orange add-to-cart-btn"
                                                        type="submit"
                                                    >
                                                        Rendelés leadása
                                                    </button>
                                                </div>
                                                <div class="voffset-90"></div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img
                                                            src="/img/simplepay.png"
                                                            style="max-width: 150px;"
                                                        />
                                                    </div>
                                                    <div class="col-md-9">
                                                        <small
                                                            >Tudomásul veszem,
                                                            hogy a(z) FARM
                                                            Burger Vác (2600
                                                            Vác, Március 15. tér
                                                            21.) adatkezelő
                                                            által a(z)
                                                            farmburgervac.hu
                                                            felhasználói
                                                            adatbázisában tárolt
                                                            alábbi személyes
                                                            adataim átadásra
                                                            kerülnek az OTP
                                                            Mobil Kft. (1093
                                                            Budapest, Közraktár
                                                            u. 30-32.), mint
                                                            adatfeldolgozó
                                                            részére. Az
                                                            adatkezelő által
                                                            továbbított adatok
                                                            köre az alábbi:
                                                            Vásárló neve, E-mail
                                                            címe, szállítási
                                                            címe. Az
                                                            adatfeldolgozó által
                                                            végzett
                                                            adatfeldolgozási
                                                            tevékenység jellege
                                                            és célja a SimplePay
                                                            Adatkezelési
                                                            tájékoztatóban, az
                                                            alábbi linken
                                                            tekinthető meg:
                                                            <a
                                                                href="http://simplepay.hu/vasarlo-aff"
                                                                target="_blank"
                                                                >http://simplepay.hu/vasarlo-aff</a
                                                            ></small
                                                        >
                                                    </div>
                                                </div>
                                                <div class="voffset-90"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import CartTable from "../components/CartTable";
import ApiService from "@/core/services/api.service";

export default {
    name: "Checkout",
    components: { CartTable },
    beforeMount() {
        const self = this;
        this.getCart();
        this.getPayments();
        this.getShipments();
        this.getCities();
        if ($cookies.get("user")) {
            this.user = $cookies.get("user");
            this.subscriptionShow = !this.user.subscribed;

            this.getShippings();
        }
        this.$http.get(`${this.url}/order/szallitasi-ido`).then(resp => {
            self.deliveryTime = resp.data.delivery_time;
            self.deliveryTimeSuburban = resp.data.delivery_time_suburban;
        });
    },
    watch: {
        cimeim_shipping: function(val) {
            const helper = this.shippings.find(t => t.id === parseInt(val));
            this.cart.shipping_lastname = helper.lastname;
            this.cart.shipping_firstname = helper.firstname;
            this.cart.shipping_city = parseInt(helper.city);
            this.cart.shipping_phone = helper.phone;
            this.cart.shipping_address = helper.address;
        },
        cimeim_billing: function(val) {
            const helper = this.shippings.find(t => t.id === val);
            this.cart.billing_lastname = helper.lastname;
            this.cart.billing_firstname = helper.firstname;
            this.cart.billing_city = parseInt(helper.city);
            this.cart.billing_phone = helper.phone;
            this.cart.billing_address = helper.address;
        }
    },
    data() {
        return {
            deliveryTime: 0,
            deliveryTimeSuburban: 0,
            cimeim_shipping: null,
            cimeim_billing: null,
            cart: {
                shipment_method_id: null,
                subscription: null
            },
            shipments: [],
            payments: [],
            shippings: [],
            cities: [],
            disabled: false,
            subscriptionShow: true,
            user: {},
            form: {
                email: null,
                password: null
            }
        };
    },
    computed: {
        filteredPayments() {
            if (parseInt(this.cart.shipment_method_id) === 1) {
                return this.payments.filter(t => t.id === 5 || t.id === 6);
            } else if (parseInt(this.cart.shipment_method_id) === 2) {
                return this.payments.filter(t => t.id !== 5 && t.id !== 6);
            }

            return this.payments;
        },
        notLoggedIn: function() {
            if (!this.user || Object.keys(this.user).length < 1) {
                return true;
            }
            return false;
        },
        shipmentPrice: function() {
            let sp = 0;

            let city = this.cities.find(t => t.id === this.cart.shipping_city);
            if (city) {
                sp += city.shipment_price_n;
            }

            let sh = this.shipments.find(
                t => t.id === this.cart.shipment_method_id
            );
            if (sh) {
                sp += sh.price_n;
            }

            if (this.cart.shipment_method_id === 1) {
                return 0;
            }

            return sp;
        },
        cartTotal: function() {
            return this.shipmentPrice + this.cart.order_total;
        },
        minOrderValue: function() {
            let sp = 0;
            let city = this.cities.find(t => t.id === this.cart.shipping_city);
            if (city) {
                sp = city.minimum_shipping_price;
            }

            return sp - this.cart.order_total;
        }
    },
    methods: {
        emptyPayment() {
            this.cart.payment_method_id = null;
            this.$forceUpdate();
        },
        getShippings() {
            const self = this;
            ApiService.get(`${self.url}/user/shipping-addresses`).then(
                response => {
                    self.shippings = response.data.data;
                    let def = self.shippings.find(t => t.is_default === 1);
                    if (def) {
                        self.cimeim_shipping = def.id;
                        self.cimeim_billing = def.id;
                    }
                }
            );
        },
        doLogin() {
            this.$http.post(`${this.url}/login`, this.form).then(response => {
                $cookies.set("id_token", response.data.token, 86400);
                $cookies.set("user", response.data, 86400);

                this.updateUserData();
                this.getShippings();
                this.user = response.data;
                this.subscriptionShow = !this.user.subscribed;
                this.$eventHub.$emit("userChanged", this.user);
            });
        },
        doOrder() {
            this.disabled = true;
            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");

            let orderEndpoint = "do";

            if (!this.notLoggedIn) {
                orderEndpoint = "do-auth";
            }

            let cartHelper = JSON.parse(JSON.stringify(self.cart));

            if (this.cart.same_billing) {
                cartHelper.billing_firstname = cartHelper.shipping_firstname;
                cartHelper.billing_lastname = cartHelper.shipping_lastname;
                cartHelper.billing_city = cartHelper.shipping_city;
                cartHelper.billing_address = cartHelper.shipping_address;
            }

            self.$http
                .post(
                    `${self.url}/order/${orderEndpoint}/${cartUuid}`,
                    cartHelper
                )
                .then(response => {
                    if (response.data.redirect) {
                        document.location.href = response.data.redirect;
                    } else {
                        localStorage.removeItem("cart_uuid");
                        self.$router.push({
                            name: "PaymentFinish",
                            query: { orderId: response.data.order.uuid }
                        });
                    }
                })
                .finally(() => {
                    self.disabled = false;
                });
        },
        update() {
            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");
            self.$http
                .post(`${self.url}/cart/update/${cartUuid}`, self.cart)
                .then(response => {
                    self.cart = response.data;
                    self.cart.shipment_method_id = null;
                    self.cart.payment_method_id = null;
                    localStorage.setItem("cart_uuid", response.data.uuid);
                    self.$forceUpdate();
                })
                .finally(() => {
                    self.updateUserData();
                });
        },
        getPayments() {
            this.$http.get(`${this.url}/payment-method/list`).then(response => {
                this.payments = response.data.data;
                this.cart.payment_method_id = null;
            });
        },
        getShipments() {
            this.$http
                .get(`${this.url}/shipment-method/list`)
                .then(response => {
                    this.shipments = response.data.data;
                    this.cart.shipment_method_id = null;
                });
        },
        getCities() {
            this.$http.get(`${this.url}/city/list`).then(response => {
                this.cities = response.data.data;
            });
        },
        updateUserData() {
            if ($cookies.get("user")) {
                const user = $cookies.get("user");
                this.cart.email = user.email;
                this.cart.billing_firstname = user.shipping.firstname;
                this.cart.billing_lastname = user.shipping.lastname;
                this.cart.billing_city = parseInt(user.billing.city);
                this.cart.billing_city_name = user.billing.city_name;
                this.cart.billing_address = user.billing.address;
                this.cart.shipping_firstname = user.shipping.firstname;
                this.cart.shipping_lastname = user.shipping.lastname;
                this.cart.shipping_city = parseInt(user.shipping.city);
                this.cart.shipping_city_name = user.shipping.city_name;
                this.cart.shipping_address = user.shipping.address;
                this.cart.phone = user.shipping.phone;
                this.subscriptionShow = !user.subscribed;
            }
        },
        getCart() {
            const cartUuid = localStorage.getItem("cart_uuid");
            this.$http
                .get(`${this.url}/cart/get/${cartUuid}`)
                .then(response => {
                    this.cart = response.data.data;
                    this.cart.shipment_method_id = null;
                    this.cart.payment_method_id = null;
                    this.cart.same_billing = true;
                    localStorage.setItem("cart_uuid", response.data.data.uuid);
                })
                .finally(() => {
                    this.updateUserData();
                    $(document).ready(function() {
                        $("#mask").hide();
                    });
                });
        }
    }
};
</script>

<style>
.checkout-form {
    max-width: 100% !important;
}

.add-to-cart-btn {
    float: none !important;
    border: solid 1px #e8bb86 !important;
    background-color: #e8bb86 !important;
}

.summary {
    border: 0px !important;
}

.customCheckout {
    background: black !important;
    color: white !important;
}

.customCheckout .white {
    color: #e8bb86;
}

.customCheckout label {
    color: black;
}

.customCheckout .booking-form-input {
    color: black;
}

.customCheckout th,
.customCheckout b,
.customCheckout td,
.customCheckout label,
.customCheckout input,
.customCheckout input:hover,
.customCheckout input:active,
.customCheckout input:focus,
.customCheckout textarea,
.customCheckout textarea:hover,
.customCheckout textarea:focus,
.customCheckout textarea:active {
    color: white !important;
}

.customCheckout textarea {
    border: 1px solid #fff;
}

#section-booking {
    padding: 0px !important;
}

.fa {
    color: white !important;
}
</style>
