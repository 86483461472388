<template>
    <!-- Menu Section Start -->
    <section id="section-ppal-menu" data-aos="fade-in" data-aos-duration="1000">
        <div class="row justify-content-md-center">
            <div class="col-12">
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-12" style="text-align: center">
                            <h4
                                class="small center t1"
                                style="font-size: 29.5px"
                            >
                                Rendeld házhoz
                            </h4>
                            <h4 class="c1 center title t2">az élményt</h4>
                            <hr />
                            <b style="color: white; text-align: center"
                                >Várható kiszállítási idő Vácon belül:
                                {{ deliveryTime }} perc</b
                            ><br />
                            <b style="color: white; text-align: center"
                                >Várható kiszállítási idő Vácon kívül:
                                {{ deliveryTimeSuburban }} perc</b
                            >
                        </div>
                    </div>
                    <div class="voffset-20"></div>
                    <div class="section ppal-menu">
                        <img
                            v-if="
                                model.products_mobile &&
                                    model.products_mobile.length > 0
                            "
                            src="/img/scrollable.png"
                            :style="'display: ' + helpImgDp"
                            class="img-fluid help-img"
                        />
                        <div class="d-block d-sm-none text-center">
                            <i class="fa fa-lg fa-arrow-left"></i>
                            <span style="color: white"
                                >&nbsp;Étlap Kategóriák&nbsp;</span
                            >
                            <i class="fa fa-lg fa-arrow-right"></i>
                        </div>
                        <nav class="tabbable">
                            <b-tabs content-class="mt-3">
                                <template
                                    v-for="(item,
                                    index) in model.products_mobile"
                                >
                                    <b-tab
                                        :title="item.name"
                                        v-bind:active="
                                            item.name === getActiveTab
                                        "
                                        v-if="item.products.length > 0"
                                        v-on:click="setActiveTab(item.name)"
                                        v-bind:key="'etlap-tab-' + index"
                                    >
                                        <div class="voffset-40"></div>
                                        <div class="row">
                                            <div
                                                class="col-lg-6 menu-elem-li"
                                                v-for="(item2,
                                                index2) in item.products"
                                                v-bind:key="
                                                    'etel-tab-' +
                                                        index +
                                                        '-' +
                                                        index2
                                                "
                                            >
                                                <ul class="menu-price">
                                                    <router-link
                                                        tag="li"
                                                        :to="{
                                                            name: 'Product',
                                                            params: {
                                                                slug: item2.slug
                                                            }
                                                        }"
                                                        style="cursor: pointer"
                                                    >
                                                        <div class="row">
                                                            <div
                                                                class="col-sm-3"
                                                            >
                                                                <img
                                                                    v-if="
                                                                        item2.image
                                                                    "
                                                                    :src="
                                                                        item2.image
                                                                    "
                                                                    class="
                                                                        img-thumbnail menu-img img-fluid
                                                                    "
                                                                    :title="
                                                                        item2.name
                                                                    "
                                                                />
                                                                <img
                                                                    v-else
                                                                    src="/img/Farm_Logo_Original.png"
                                                                    class="
                                                                        img-thumbnail menu-img img-fluid
                                                                    "
                                                                    :title="
                                                                        item2.name
                                                                    "
                                                                />
                                                            </div>
                                                            <div
                                                                class="col-sm-9"
                                                            >
                                                                <div
                                                                    class="row"
                                                                >
                                                                    <div
                                                                        class="col-sm-12 row menu-felirat"
                                                                        style="padding-right: 0px"
                                                                    >
                                                                        <div
                                                                            class="col-sm-9"
                                                                        >
                                                                            <p
                                                                                class="item-name"
                                                                            >
                                                                                {{
                                                                                    item2.name
                                                                                }}
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            class="col-sm-3 text-right onlydesktop"
                                                                        >
                                                                            <p>
                                                                                <span
                                                                                    style="white-space: nowrap"
                                                                                    >{{
                                                                                        item2.price_formatted
                                                                                    }}</span
                                                                                >
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-8 menu-felirat-2"
                                                                    >
                                                                        <p
                                                                            v-if="
                                                                                item.name !==
                                                                                    'Desszertek'
                                                                            "
                                                                            class="ingredients"
                                                                            v-html="
                                                                                item2.description
                                                                            "
                                                                        ></p>
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-4 menu-felirat-3"
                                                                        style="padding-left: 0px !important;"
                                                                    >
                                                                        <p
                                                                            class="mobile-only"
                                                                            style="float: left !important; padding-top: 5px;"
                                                                        >
                                                                            <span
                                                                                style="white-space: nowrap"
                                                                                >{{
                                                                                    item2.price_formatted
                                                                                }}</span
                                                                            >
                                                                        </p>
                                                                        <button
                                                                            style="border-radius: 5px"
                                                                            class="menu-btn"
                                                                        >
                                                                            Rendelés
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="row mobile-only"
                                                        >
                                                            <div
                                                                class="col-sm-12"
                                                            >
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </ul>
                                            </div>
                                        </div>
                                    </b-tab>
                                </template>
                            </b-tabs>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Menu Section End -->
</template>

<script>
import LazyImage from "./LazyImage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
    name: "FullMenu",
    components: { LazyImage, VueSlickCarousel },
    data() {
        return {
            deliveryTime: 0,
            deliveryTimeSuburban: 0,
            model: {},
            helpImgDp: "none"
        };
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        getActiveTab: function() {
            let activeTab = localStorage.getItem("activeTab");

            if (activeTab) {
                return activeTab;
            } else {
                return "Burgerek";
            }
        }
    },
    beforeMount() {
        const self = this;
        this.getMenu();
        this.$http.get(`${this.url}/order/szallitasi-ido`).then(resp => {
            self.deliveryTime = resp.data.delivery_time;
            self.deliveryTimeSuburban = resp.data.delivery_time_suburban;
        });
    },
    methods: {
        setActiveTab(name) {
            localStorage.setItem("activeTab", name);
        },
        getMenu() {
            const self = this;
            this.$http
                .get(`${this.url}/product/list`)
                .then(resp => {
                    this.model = resp.data;
                })
                .finally(() => {
                    setTimeout(function() {
                        self.helpImgDp = "none";
                    }, 5000);
                });
        }
    }
};
</script>

<style>
.masonry {
    /* Masonry container */
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 1em;
    -moz-column-gap: 1em;
    column-gap: 1em;
    margin: 1.5em;
    padding: 0;
    -moz-column-gap: 1.5em;
    -webkit-column-gap: 1.5em;
    column-gap: 1.5em;
    font-size: 0.85em;
}

.item {
    display: inline-block;
    padding: 1em;
    margin: 0 0 1.5em;
    width: 100%;
    -webkit-transition: 1s ease all;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.item img {
    max-width: 100%;
    height: auto;
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 320px) {
    .masonry {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
    .masonry {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
    .masonry {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

.tabbable .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.tabbable .nav-tabs .nav-link {
    white-space: nowrap;
}

.tabbable a {
    color: #e8bb86;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: #e8bb86;
    color: #fff;
}

p.ingredients > p {
    font-size: 13px !important;
    font-weight: normal !important;
}

.menu-img {
    max-width: 150px !important;
}

.column-f {
    float: left;
    width: 30%;
    padding: 5px;
}

.column-s {
    float: left;
    width: 70%;
    padding: 5px;
}

.c1 {
    font-size: 29.5px;
}

.t1 {
    margin-bottom: 0px !important;
}

.t2 {
    font-weight: normal !important;
}

.img-thumbnail {
    background-color: #e8bb86 !important;
    border: 1px solid #e8bb86 !important;
}

.slick-slide h3 {
    color: #e8bb86;
    font-size: 16px;
}

.menu-price {
    margin-top: 10px !important;
}

.nav-tabs {
    border-bottom: 0px !important;
}

a:hover {
    color: #fff !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #000 !important;
}

@media only screen and (min-width: 769px) {
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #000;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.menu-btn {
    font-family: "Nunito Sans", sans-serif;
    border: solid 1px #e8bb86;
    background-color: #e8bb86;
    color: #ffffff;
    display: inline-block;
    float: right;
    font-weight: 500;
    font-stretch: normal;
    font-size: 15.5px;
    text-align: center;
    line-height: 12px;
    padding: 15px 17px;
    transition: all 0.5s ease !important;
    cursor: pointer;
}

@media only screen and (max-width: 769px) {
    .col-sm-3 {
        width: 33% !important;
    }

    .col-sm-9 {
        width: 66% !important;
    }

    .img-thumbnail {
        max-width: 100% !important;
    }

    .menu-btn {
        float: right;
    }

    .menu-elem-li {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .menu-felirat {
        padding-left: 0px !important;
    }

    .menu-felirat-2 {
        padding-left: 0px !important;
    }

    .item-name {
        line-height: 1 !important;
        font-size: 15px !important;
    }

    .ingredients {
        line-height: 1 !important;
        font-size: 14px !important;
    }

    .mobile-only {
        display: block;
    }
}

@media only screen and (max-width: 769px) {
    .help-img {
        filter: invert(1);
        position: absolute;
        text-align: center;
        max-width: 150px;
        animation: blink 1s;
        animation-iteration-count: infinite;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 0.5;
        }
        50% {
            opacity: 0;
        }
        75% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
}

@media only screen and (min-width: 769px) {
    .help-img {
        display: none;
    }
}

@media only screen and (min-width: 1201px) {
    .masonry {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 1201px) {
    .menu-img {
        max-width: 100% !important;
    }
}
</style>
