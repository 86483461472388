var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"section-ppal-menu","data-aos":"fade-in","data-aos-duration":"1000"}},[_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row "},[_c('div',{staticClass:"col-lg-12",staticStyle:{"text-align":"center"}},[_c('h4',{staticClass:"small center t1",staticStyle:{"font-size":"29.5px"}},[_vm._v(" Rendeld házhoz ")]),_c('h4',{staticClass:"c1 center title t2"},[_vm._v("az élményt")]),_c('hr'),_c('b',{staticStyle:{"color":"white","text-align":"center"}},[_vm._v("Várható kiszállítási idő Vácon belül: "+_vm._s(_vm.deliveryTime)+" perc")]),_c('br'),_c('b',{staticStyle:{"color":"white","text-align":"center"}},[_vm._v("Várható kiszállítási idő Vácon kívül: "+_vm._s(_vm.deliveryTimeSuburban)+" perc")])])]),_c('div',{staticClass:"voffset-20"}),_c('div',{staticClass:"section ppal-menu"},[(
                            _vm.model.products_mobile &&
                                _vm.model.products_mobile.length > 0
                        )?_c('img',{staticClass:"img-fluid help-img",style:('display: ' + _vm.helpImgDp),attrs:{"src":"/img/scrollable.png"}}):_vm._e(),_vm._m(0),_c('nav',{staticClass:"tabbable"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_vm._l((_vm.model.products_mobile),function(item,index){return [(item.products.length > 0)?_c('b-tab',{key:'etlap-tab-' + index,attrs:{"title":item.name,"active":item.name === _vm.getActiveTab},on:{"click":function($event){return _vm.setActiveTab(item.name)}}},[_c('div',{staticClass:"voffset-40"}),_c('div',{staticClass:"row"},_vm._l((item.products),function(item2,index2){return _c('div',{key:'etel-tab-' +
                                                    index +
                                                    '-' +
                                                    index2,staticClass:"col-lg-6 menu-elem-li"},[_c('ul',{staticClass:"menu-price"},[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"tag":"li","to":{
                                                        name: 'Product',
                                                        params: {
                                                            slug: item2.slug
                                                        }
                                                    }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[(
                                                                    item2.image
                                                                )?_c('img',{staticClass:"\n                                                                    img-thumbnail menu-img img-fluid\n                                                                ",attrs:{"src":item2.image,"title":item2.name}}):_c('img',{staticClass:"\n                                                                    img-thumbnail menu-img img-fluid\n                                                                ",attrs:{"src":"/img/Farm_Logo_Original.png","title":item2.name}})]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 row menu-felirat",staticStyle:{"padding-right":"0px"}},[_c('div',{staticClass:"col-sm-9"},[_c('p',{staticClass:"item-name"},[_vm._v(" "+_vm._s(item2.name)+" ")])]),_c('div',{staticClass:"col-sm-3 text-right onlydesktop"},[_c('p',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item2.price_formatted))])])])]),_c('div',{staticClass:"col-sm-8 menu-felirat-2"},[(
                                                                            item.name !==
                                                                                'Desszertek'
                                                                        )?_c('p',{staticClass:"ingredients",domProps:{"innerHTML":_vm._s(
                                                                            item2.description
                                                                        )}}):_vm._e()]),_c('div',{staticClass:"col-sm-4 menu-felirat-3",staticStyle:{"padding-left":"0px !important"}},[_c('p',{staticClass:"mobile-only",staticStyle:{"float":"left !important","padding-top":"5px"}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item2.price_formatted))])]),_c('button',{staticClass:"menu-btn",staticStyle:{"border-radius":"5px"}},[_vm._v(" Rendelés ")])])])])]),_c('div',{staticClass:"row mobile-only"},[_c('div',{staticClass:"col-sm-12"},[_c('hr')])])])],1)])}),0)]):_vm._e()]})],2)],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block d-sm-none text-center"},[_c('i',{staticClass:"fa fa-lg fa-arrow-left"}),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" Étlap Kategóriák ")]),_c('i',{staticClass:"fa fa-lg fa-arrow-right"})])}]

export { render, staticRenderFns }